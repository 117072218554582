<div mat-dialog-content>
    <div class="reg-success-dialog">
        <div class="close-icon">
          <button mat-icon-button class="close-button">
            <mat-icon class="icon" (click)="onNoClick()" color="warn">close</mat-icon>
          </button>
        </div>
        <div class="mat-content">
            <!-- <h1>notification</h1> -->
            <p class="order-no">Thank you for your interest in the Dryo Laundry Service. Currently, 
                we do not have services in your area. As in when service is available in your area, we will get in touch with you</p>
            <img class="logo" src="./assets/images/logo1.png" width="100px">
        </div>
    </div>
</div>


 