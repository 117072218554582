  <div class="landing-page screen2">
    <div class="row">
      <div class="col-sm-7 col-md-6 col-lg-6 col-1">
        <img class="bg" src="./assets/images/Icon__1-05.png">
      </div>
      <div class="content content1 col-sm-5 col-md-6 col-lg-6 col-2">
       <div class="row">
          <div class="col-sm-12">
            <img class="logo" src="./assets/images/dryo-logo-b.png">
            <h1>One app cleans it all</h1>
            <p>You deserve to live spotless,  leave the cleaning to us and keep the living to you. dryo is available by invite only.</p>
              <p>Please provide your information to sign-up for invitations when dryo is available in your area.
             </p>
          </div>
        </div>  
        <form class="row phone-email login-form" #f="ngForm" [formGroup]="notifyForm">
            <div class="email col-sm-6">
              <mat-form-field dense color="primary"  appearance="standard">
                <input matInput formControlName="email" placeholder="email address">         
                <mat-error *ngIf="f.form.controls['email']?.errors?.required">email is required</mat-error>
                <mat-error *ngIf="f.form.controls['email']?.errors?.email">email must be a valid email address</mat-error>
              </mat-form-field>
            </div>
            <div class="phone col-sm-6">
              <mat-form-field dense  color="primary" appearance="fill"
                class="field-6 mat-country-selection mat-country-selection1">
                <ngx-mat-intl-tel-input inputPlaceholder="phone number" [preferredCountries]="['us','in']" [enablePlaceholder]="true" name="phone"
                  (countryChanged)="CountryChangedEvent($event)" formControlName="phone" #phone>
                </ngx-mat-intl-tel-input>
                <mat-error *ngIf="f.form.controls['phone']?.errors?.required">phone is required</mat-error>
                <mat-error *ngIf="f.form.controls['phone']?.errors?.validatePhoneNumber">invalid number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="email col-sm-6 ">
              <mat-form-field dense  color="primary" appearance="fill" >
                <mat-select disableOptionCentering formControlName="country" class="countries1" placeholder="country">
                  <mat-option *ngFor="let country of countries" [value]="country">
                    {{country.value}}
                  </mat-option>
                </mat-select>           
                <mat-error *ngIf="f.form.controls['country']?.errors?.required">country is required</mat-error>
              </mat-form-field>
            </div>
            
            <div class="email col-sm-6 ">
              <mat-form-field color="primary" dense appearance="fill">
                <input 
                (keypress)="numberOnly($event)" matInput formControlName="zipcode" maxlength="6" placeholder="zipcode">
                <mat-error *ngIf="f.form.controls['zipcode']?.errors?.required">zipcode id is required</mat-error>
              </mat-form-field>
            </div>
        </form>
       <div class="row">
        <div class="col-sm-12">
          <button class="notify-me"  (click)="onNotify()">notify me</button>
          <p class="privacy-policy">by clicking notify me, you agree to our <a>privacy policy.</a></p>
        </div>
        </div>
      </div>
  </div>
  </div>