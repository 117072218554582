import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Screen1Component } from './landing-screen/screen1.component';
import { Screen2Component } from './screen2/screen2.component';


const routes: Routes = [
  {
    path:'',
    component:Screen1Component
  },
  {
    path:'1',
    component:Screen2Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
