import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotifyDialogComponent } from '../notify-dialog/notify-dialog.component';
import { NotificationService } from '../services/notification.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-screen2',
  templateUrl: './screen2.component.html',
  styleUrls: ['./screen2.component.scss']
})
export class Screen2Component implements OnInit, AfterViewInit {
  countries = [
    {id: 231, value: 'United States'},
    {id: 229, value: 'United Arab Emirates'},
    {id: 101, value: 'India'},
  ];
  countrySel = [];
  ngOnInit(): void {
    //this.ipLookUp();
  }
  
  title = 'prelaunch';
  @ViewChildren('phone') regNumber:any ;
  dialCode: any;
  constructor(
    private fb: FormBuilder,
    private notificationService:NotificationService,
    private dialog: MatDialog, private http: HttpClient
    ){

  }
  notifyForm = this.fb.group({
    email:[null, [Validators.required]],
    phone:[null, [Validators.required]],
    country:[null, [Validators.required]],
    zipcode:[null, [Validators.required]]
  });


  CountryChangedEvent(e:any){
    this.dialCode =  e.dialCode;
  }
  onNotify(){
    
    this.notifyForm.markAllAsTouched();
    let notify = this.notifyForm.value;
    //notify.email ? this.notifyForm.controls['phone'].setErrors(null) : notify.phone ? this.notifyForm.controls['email'].setErrors(null) : ''
    if((notify.email || notify.phone ) && this.notifyForm.valid){
      if(notify.phone) {
        var phoneComponents = {
          number: notify.phone.substring(this.dialCode.length + 1, notify.phone.length)
        };
      }
      const loginData = {
        email: notify.email ? notify.email: '',
        countryCode: this.dialCode ? this.dialCode : '',
        phoneNumber: phoneComponents ? phoneComponents.number:'',
        countryId:notify.country.id,
        zipCode:notify.zipcode
      };
      console.log(loginData);
      this.notificationService.notify(loginData)
        .subscribe(
          (data:any) => {
            if (data.status=='success') {
              // alert("Success");
              this.onDialog();
            }
          },
          (error) => {
          });
    }
  }

  onDialog() {
    const dialogRef = this.dialog.open(NotifyDialogComponent, {
      width: 'auto',
      height:'100%',
      data: {},
      panelClass: 'custom-dialog-container' 
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.notifyForm.controls['email'].setValue(null);
      this.notifyForm.controls['phone'].setValue(null);
      // this.regNumber.value = '';
      // this.regNumber.nativeElement.value = '';
      //this.animal = result;
      this.notifyForm.reset();
      let phone = document.getElementsByClassName('mat-country-selection');
      // phone.childr
      console.log(phone);
    });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngAfterViewInit(){
    //this.regNumber.first.elRef.nativeElement.children[0].children[2].placeholder="phone number";
    this.regNumber.first.elRef.nativeElement.children[0].children[2].classList.remove('mat-input-element');
  }

  ipLookUp () {
    this.http.get('http://ip-api.com/json').subscribe(
      (response:any)=>{
        console.log('User\'s Location Data is ', response);
            console.log('User\'s Country', response.country);
            let userCountry = this.countries.find(_country => _country.value === response.country);
            if(userCountry) {
             // this.notifyForm.get('country').setValue(userCountry);
             // this.countrySel = [response.countryCode?.toLowerCase()];
            }
      },
      (err)=> {
        console.log('Request failed.  Returned status of',
        err);
      }
    )
  }
}
