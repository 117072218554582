import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '../services/notification.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NotifyDialogComponent } from '../notify-dialog/notify-dialog.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-screen1',
  templateUrl: './screen1.component.html',
  styleUrls: ['./screen1.component.scss']
})
export class Screen1Component implements OnInit, AfterViewInit {
  @ViewChild('RegDialog', { static: true }) RegDialog: TemplateRef<any>;
  title = 'prelaunch';
  @ViewChildren('phone') regNumber:any ;
  dialCode: any;
  submitted: boolean = false;
  countries = [
    {id: 231, value: 'United States', flag:'us'},
    {id: 229, value: 'United Arab Emirates', flag: 'ae'},
    {id: 101, value: 'India', flag:'in'},
  ];
  countrySel = [];
  constructor(
     private fb: FormBuilder,
     private notificationService:NotificationService,
     private dialog: MatDialog,
     private http:HttpClient){

  }
  notifyForm = this.fb.group({
    email:[null,[Validators.email, Validators.required]],
    phone:[null, [Validators.required]],
    country:[null, [Validators.required]],
    zipcode:[null, [Validators.required]]
  });

  ngOnInit(){
   //this.ipLookUp();
  }

  CountryChangedEvent(e:any){
    this.dialCode =  e.dialCode;
  }
  onNotify(){

    this.notifyForm.markAllAsTouched();
    let notify = this.notifyForm.value;
    this.submitted = true;

    if((notify.email || notify.phone ) && this.notifyForm.valid){
      if(notify.phone) {
        var phoneComponents = {
          number: notify.phone.substring(this.dialCode.length + 1, notify.phone.length)
        };
      }
      const loginData = {
        email: notify.email ? notify.email: '',
        countryCode: this.dialCode ? this.dialCode : '',
        phoneNumber: phoneComponents ? phoneComponents.number:'',
        countryId:notify.country.id,
        zipCode:notify.zipcode
      };
      this.notificationService.notify(loginData)
        .subscribe(
          (data:any) => {
            if (data.status=='success') {
              this.onDialog();
            }
          },
          (error) => {
          });
    }
  }

  ngAfterViewInit(){
    this.regNumber.first.elRef.nativeElement.children[0].children[2].classList.remove('mat-input-element');
  }

  onDialog() {
    const dialogRef = this.dialog.open(NotifyDialogComponent, {
      width: 'auto',
      height:'100%',
      data: {},
      panelClass: 'custom-dialog-container' 
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.notifyForm.controls['email'].setValue(null);
      this.notifyForm.controls['phone'].setValue(null);
      this.notifyForm.reset();
      let phone = document.getElementsByClassName('mat-country-selection');
      console.log(phone);
    });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ipLookUp () {
    this.http.get('https://ipinfo.io').subscribe(
      (response:any)=>{
        console.log('User\'s Location Data is ', response);
            console.log('UpreferredCountriesser\'s Country', response.country);
            let userCountry = this.countries.find(_country => _country.value === response.country);
            if(userCountry) {
             this.notifyForm.get('country').setValue(userCountry);
             this.countrySel = [response.countryCode?.toLowerCase()];
            }
      },
      (err)=> {
        console.log('Request failed.  Returned status of',
        err);
      }
    )
  }
}
